@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.wrap {
  display: none;
  animation: fadeIn 0.3s ease-in;
  overflow: hidden;
}

.wrap[data-init-show='true'] {
  display: flex;
}

.wrap[data-show='false'] {
  animation: fadeOut 0.3s ease-in forwards;
}

.leftWrap,
.rightWrap {
  display: flex;
  flex-direction: column;
}

.centerWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.centerWrap[data-icon-pos='left'] {
  align-items: flex-start;
  text-align: left;
}

.centerWrap[data-icon-pos='right'] {
  align-items: flex-end;
  text-align: right;
}

.message {
  display: flex;
}
