.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.root[disabled] {
  cursor: not-allowed;
}
