.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.leftWrap {
  display: flex;
  align-items: center;
}

.rightWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pageButtonsWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 2px;
}

.recordCount {
  margin-left: 14px;
  white-space: nowrap;
}

.paginationButton {
  padding: 10px;
  min-width: 34px;
  height: 34px;
  justify-content: center;
}

.pageInput {
  margin-left: 10px;
  width: 50px;
}
